<template>
    <div>
        <table class="table table-striped table-head-fixed text-nowrap">
            <thead>
                <tr>
                    <th class="pl-2 pt-2 pb-2 text-nowrap" style="width: 1%;">
                        <div>Avatar</div>
                    </th>
                    <th class="pl-2 pt-2 pb-2">
                        <div>Name / Address / Area, ..</div>
                    </th>
                    <!-- <th class="pl-2 pt-2 pb-2 text-left" style="width: 1%;">
                        <div>Source</div>
                    </th> -->
                    <th class="pl-2 pt-2 pb-2 text-left" style="max-width: 15rem;">
                        <div>Staff note</div>
                    </th>
                    <th class="pl-2 pt-2 pb-2 text-center" style="max-width: 18rem;">
                        <div>Staff t.care</div>
                    </th>
                    <th class="pl-2 pt-2 pb-2 text-left" style="width: 1%;">
                        <div>Email / Phone number</div>
                    </th>
                    <th class="pl-2 pt-2 pb-2 text-left" style="width: 1%;">
                        <div>Details</div>
                    </th>
                    <slot name="table-header"></slot>
                </tr>
            </thead>
            <tbody>
                <tr :key="element.id" v-for="element in elements"
                    v-bind:class="{ 'low-balance' : ($store.getters.lowBalanceUserId.includes(element.id)) }">
                    <td class="v-center pl-2 pt-1 pb-1">
                        <div class="profile-image-ctn position-relative">
                            <a href="javascript:void(0);"  @click="checkAccess('USER_VIEW_DETAIL') ? $getUserDetailInfo(element.id) : none()">
                                <div v-if="element.profilePhoto !== null && element.profilePhoto !== ''">
                                    <div v-if="!element.profilePhoto.includes('http')">
                                        <img  v-if="!element.profilePhoto.includes('data:image')"
                                                class="profile-image rounded" 
                                                :src="`data:image/jpeg;base64,${element.profilePhoto}`"
                                                onerror="this.src='/img/avatars/male.png'" />
                                        <img  v-else
                                                class="profile-image rounded" :src="element.profilePhoto"
                                                onerror="this.src='/img/avatars/male.png'" />
                                    </div>
                                    <img v-else class="profile-image rounded" :src="element.profilePhoto" 
                                                onerror="this.src='/img/avatars/male.png'"/>
                                </div>
                                <div v-else>                                                
                                    <img  class="profile-image" src="/img/avatars/male.png" />
                                </div>
                            </a>
                            <div v-if="$store.getters.lowBalanceUserId.includes(element.id)"
                                    class="position-absolute d-flex align-items-center top-1 right-1 left-1 bottom-1"
                                    v-bind:class="{ 'blink_me2s' : ($store.getters.lowBalanceUserId.includes(element.id)) }">
                                <button type="button" data-toggle="tooltip" title="Warning low balance GPU Hub. Click to send mail warning"
                                        @click="sendMailWarningLowBalance(element)">
                                    <img src="/img/exclamation.svg" style="width: 2.5rem;"/>
                                </button>
                            </div>
                        </div>
                    </td>
                    <td class="v-center text-nowrap pl-2 pt-1 pb-1">
                        <div class="d-flex align-content-center align-items-center justify-content-center">
                            <div class="flex-fill d-flex flex-column align-content-center justify-content-center position-relative">
                                <div>
                                    <a href="javascript:void(0);"  @click="checkAccess('USER_VIEW_DETAIL') ? $getUserDetailInfo(element.id) : none()">
                                        <strong v-bind:class="{ 'text-danger' : ($store.getters.lowBalanceUserId.includes(element.id)) }">
                                            <span>{{element.fullName}}</span>
                                        </strong>
                                    </a>
                                                
                                    <button type="button" class="btn btn-xs btn-default rounded p-0 pl-1 pr-1 ml-1" 
                                            data-toggle="tooltip" title="Click copy username to clipboard"
                                            @click="copyToClipboard('#userCopy', element.userName)"
                                            style="font-size: 10px; position: relative; top: -2px;">
                                        <i class="fa fa-copy text-muted"></i>
                                    </button>
                                </div>
                                <div>
                                    <small v-if="element.userRole && element.userRole !== ''" 
                                        class="text-lowercase label bg-gradient-info mr-1">
                                        {{element.userRole}}
                                    </small>
                                    <small v-if="element.appCode" :class="`mr-1 label ${$appCode[element.appCode]}`">
                                        {{element.appCode}}
                                    </small>
                                    <small v-if="element.totaL_RECHARGE_BAL2 > 0"
                                           class="mr-1 label bg-gradient-primary">
                                        Paid
                                    </small>
                                    <small v-if="element.totaL_RECHARGE_BAL2 >= $primeUserMinAmount"
                                           class="mr-1 label bg-gradient-navy">
                                        Prime
                                    </small>
                                </div>

                                <div>                                    
                                    <small class="text-muted mb-0 mr-1" >
                                        <img :src="`/img/countries/${element.region}.png`" 
                                                class="position-relative mr-0"  onerror="this.style = 'display: none;';"
                                                style="top: -1px;"/>&nbsp;
                                        <span v-if="Object.keys($countryList).includes(element.region)">{{$countryList[element.region]}}</span>
                                        <span v-else>{{element.region}}</span>
                                    </small>
                                    <small><i class="text-info far fa-calendar-plus"></i> <i>Created <strong>{{element.dateCreatedAt}}</strong></i></small>                                    
                                </div>
                            </div>
                        </div>
                    </td>
                    <!-- <td class="v-center  text-left pl-2 pt-1 pb-1" :title="element.dateUpdatedAt">
                        <strong class="text-nowrap text-capitalize size-90pc">
                            {{element.nationality}}
                        </strong>
                    </td> -->
                    <td class="v-center text-nowrap pl-2 pt-1 pb-1 text-left">
                        <div :id="`note_${element.id}`"
                                class="d-flex align-content-center  align-items-center mr-2">
                            <div class="cursor-pointer user-note-content p-1 mb-0"
                                    v-if="element.noteContent" @click="performAction('viewDetailNote', element)">
                                <div class="d-flex align-items-center">
                                    <v-avatar size="3rem">
                                        <v-img id="app-avatar" :src="`/img/avatars/${element.staffSupport}.jpg`" 
                                                            onerror="this.src='/img/avatar5.png'" />
                                    </v-avatar>
                                    <div v-html="element.noteContent" class="alert bg-light  p-1 mb-0 ml-1"  data-toggle="tooltip" title="Click to view more"
                                            style="max-width: 15rem;max-height: 2.6rem;overflow: hidden;white-space: normal !important;font-size: 0.8rem;">
                                    </div>
                                </div>
                            </div>
                            <div v-else class="text-muted mr-2">
                                <button type="button" class="btn btn-default" @click="editDetailNote(element)">
                                    <i class="fa fa-plus-circle"></i> Add note
                                </button>
                            </div>
                        </div>
                    </td>
                    <td class="v-center text-nowrap pl-2 pt-1 pb-1 text-center">
                        <div v-if="element.staffTakeCare !== null && element.staffTakeCare !== ''">
                            <v-avatar size="3rem">
                                <v-img id="app-avatar" :src="`/img/avatars/${element.staffTakeCare}.jpg`" 
                                                    onerror="this.src='/img/avatar5.png'" />
                            </v-avatar>
                        </div>
                        <div v-else>
                            <button type="button" class="btn btn-sm btn-default" @click="performAction('Accept', element)">
                                <i class="fa fa-check"></i> Accept this
                            </button>
                        </div>
                    </td>
                    <td class="v-center pl-2 pt-1 pb-1">
                        <div class="text-nowrap">
                            <small class="size-90pc"><i class="fa fa-calendar text-info"></i>  <i>Born <strong>{{element.dateBirthday}}</strong></i></small>
                        </div>
                        <div class="text-nowrap">
                            <a class="text-primary" :href="`mailto:${element.email}`">
                                <small class="size-90pc"><i class="far fa-envelope"></i> {{element.email}}</small>
                            </a>
                            <button type="button" class="btn btn-xs btn-default rounded p-0 pl-1 pr-1 ml-1" 
                                    data-toggle="tooltip" title="Click copy email to clipboard"
                                    @click="copyToClipboard('#userCopy', element.email)"
                                    style="font-size: 10px; position: relative; top: -2px;">
                                <i class="fa fa-copy text-muted"></i>
                            </button>
                        </div>
                        <div v-if="element.phone">
                            <a class="text-info" :href="`tel:${element.phone}`">
                                <small class="size-90pc"><i class="fas fa-phone-square"></i> {{element.phone}}</small>
                            </a>
                            <button type="button" class="btn btn-xs btn-default rounded p-0 pl-1 pr-1 ml-1" 
                                    data-toggle="tooltip" title="Click copy phone to clipboard"
                                    @click="copyToClipboard('#userCopy', element.phone)"
                                    style="font-size: 10px; position: relative; top: -2px;">
                                <i class="fa fa-copy text-muted"></i>
                            </button>
                        </div>
                    </td>
                    <td class="v-center text-nowrap text-left pl-2 pt-1 pb-1">
                        <div>
                            <small class="size-90pc text-muted" v-if="element.imageCount > 0">
                                Created <strong>{{element.imageCount}} {{element.imageCount > 1 ? 'images' : 'image'}}</strong>
                            </small>
                            <small class="size-90pc text-muted" v-else>
                                No image created yet
                            </small>
                        </div>
                        <div  v-if="element.lastBilling" >                            
                            <small
                                class="size-90pc text-muted">
                                Last billing <strong>{{element.lastBilling | pretyDateUtc}}</strong>
                            </small>
                        </div>
                        <div>
                            <small class="size-90pc text-muted"
                                    data-toggle="tooltip" :title="`iRender farm: ${(element.totaL_RECHARGE_BAL1)} ${$pointNameShort}, GPU Hub: ${element.totaL_RECHARGE_BAL2} ${$pointNameShort}`">
                                Total recharge <strong class="text-primary">{{element.totaL_RECHARGE_BAL | numFormat('0,0[.]0000')}} {{$pointNameShort}}</strong>
                            </small>
                        </div>
                    </td>
                    <slot name="table-content" v-bind="{element}"></slot>
                    
                </tr>
            </tbody>
        </table>
    </div>
</template>


<script>
    import { mapState } from "vuex";
    import baseComponent from "@/scripts/baseComponent";
    export default {
        extends: baseComponent,
        props: {
            elements: {
                type: Array,
                default() { return []; },
            },
            warningList: {
                type: Array,
                default() { return []; },
            }
        },
        computed: {        
            ...mapState({
                $primeUserMinAmount: (state) => state.primeUserMinAmount,
            }),
        },
        methods: {
            performAction(actionName, data) {
                this.$emit("actions", { actionName: actionName, actionData: data });
            }
        }
    }
</script>